const notificationValidator = (values) => {
  console.log("vvvvvvvvvvvv", values);
  let errors = {};
  if (values?.notificationPreference === "cloudPic") {
    if (!values.cloudType) {
      errors.cloudType = "Cloud Type is required";
    }

    if (!values.contactType) {
      errors.contactType = "Contact Type is required";
    }

    if (!values.subject) {
      errors.subject = "Subject is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    if (values.isAllAccountSelected === false) {
      if (
        values.validatedAccountName === null &&
        values.validatedAccountNumber === null
      ) {
        errors.optionalValue =
          "Either account name or account number is required";
      }
    }
  } else {
    if (!values.subject) {
      errors.subject = "Subject is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }
    if (values.recepients === null) {
      errors.recepients = "Recepients is required";
    }
  }

  return errors;
};

export { notificationValidator };
