import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import Notification from "./Notification";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Notification_System,
} from "roles/roles";

const NotificationIndex = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (
          !info.groups.includes(CS_Hub_AWS_Account_Request_Admin) &&
          !info.groups.includes(CS_Hub_AWS_Notification_System)
        ) {
          history.push("/user-dashboard");
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <Container fluid className="mb-3">
          <Row>
            <Col md={12} className="mt-5">
              <Notification />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default NotificationIndex;
